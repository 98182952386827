import React, { Suspense, createContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import Main from './Main';
import SiderMenu from './siderMenu';
import Toolbar from './Toolbar';
import Loading from '../pages/Loading';
import { websocket } from '../components/websocket';
import ServicePlanFactory from '../ServicePlan/ServicePlanFactory';
import UseSuppiler from '../hooks/UseSuppiler';
import firebase from '../firebase';
import { fetchApi } from '../services/ApiService';
import { getReadMode } from '../services/NoticeApi';
import firebaseConfig from '../firebase/firebaseConfig';
import { putPageSetting } from '../services/PublicApi';
export const AppContext = createContext();

const PublicLayout = () => {
    let supplier = UseSuppiler().supplier;
    const websocketData = websocket();
    const [servicePlan, setServicePlan] = useState();
    const [firebaseNotice, setFirebaseNotice] = useState();
    const [readMode, setReadMode] = useState();

    useEffect(() => {
        if (supplier) {
            firebaseConfig(setFirebaseNotice);
            setServicePlan(ServicePlanFactory(supplier.service_plan));
            firebase(setFirebaseNotice);
            fetchReadMode();
        }
    }, [supplier]);

    const fetchReadMode = async () => {
        const result = await fetchApi(getReadMode, {
            setting_page: 'enable_notification',
        });

        if (result.data.length === 0) {
            updateUserSettings([
                {
                    setting_page: 'enable_notification',
                    setting_name: '讀取資料',
                    setting_content: {
                        read: true,
                    },
                },
            ]);
            setReadMode(true);
        } else {
            setReadMode(result.data[0].user_setting_content.read);
        }
    };

    const updateUserSettings = async (updates) => {
        if (updates.length === 0) return;

        try {
            await fetchApi(putPageSetting, { setting_list: updates });
            console.warn('已更新UserSettings');
        } catch (error) {
            console.error('更新失敗', error);
        }
    };
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AppContext.Provider
                value={{
                    websocketData,
                    firebaseNotice,
                    readMode,
                    setReadMode,
                    servicePlan,
                    supplier,
                }}
            >
                <Toolbar />
                <Layout>
                    <SiderMenu />
                    {servicePlan ? (
                        <Suspense fallback={<Loading />}>
                            <Main />
                        </Suspense>
                    ) : (
                        <Loading />
                    )}
                </Layout>
            </AppContext.Provider>
        </Layout>
    );
};

export default PublicLayout;
