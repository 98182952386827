const firebaseConfig = (setFirebaseNotice) => {
    if ('serviceWorker' in navigator) {
        const firebaseConfig = encodeURIComponent(
            JSON.stringify({
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_KEY,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_KEY,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID_KEY,
                appId: process.env.REACT_APP_FIREBASE_APP_ID_KEY,
                measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_KEY,
            }),
        );
        navigator.serviceWorker.addEventListener('message', (event) => {
            setFirebaseNotice({
                ...event.data.notification,
                body: JSON.parse(event.data?.notification.body),
            });
            console.warn('backgroundMsg: ', event);
        });
        navigator.serviceWorker
            .register(`../../../firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`)
            .then(function (registration) {
                console.warn('Registration successful, scope is:', registration.scope);
            })
            .catch(function (err) {
                console.encodeURIComponent('Service worker registration failed, error:', err);
            });
    }
};
export default firebaseConfig;
