import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { fetchApi } from '../services/ApiService';
import { subscribeFCM } from '../services/PublicApi';
import { message } from 'antd';

const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_KEY,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_KEY,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_KEY,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_KEY,
});

const messaging = getMessaging(app);

const firebase = (setFirebaseNotice) => {
    try {
        Notification.requestPermission((permission) => {
            if (permission === 'granted') {
                console.warn('permission', permission);
                getToken(messaging).then((token) => {
                    localStorage.setItem('firebaseToken', token);
                    fetchApi(subscribeFCM, { fcmToken: token }).then((res) => {
                        if (res.status === 200) {
                            onMessage(messaging, (payload) => {
                                console.warn('permission', payload);
                                setFirebaseNotice({
                                    ...payload.notification,
                                    body: JSON.parse(payload?.notification.body),
                                });
                            });
                        }
                    });
                });
            } else {
                message.error('使用者拒絕通知，應用程式無法發送通知。請開啟後重新載入網頁');
            }
        });
    } catch (error) {
        console.error('Error requesting notification permission:', error);
    }
};

export default firebase;
